import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { UserState, User, initUser } from "./initialState";

const hashCode = process.env.NEXT_PUBLIC_HASH_CODE;
if (!hashCode) {
  throw new Error("NEXT_PUBLIC_HASH_CODE environment variable is not set");
}

// Function to encrypt user information
const encryptUserInfo = (userInfo: User) => {
  return CryptoJS.AES.encrypt(JSON.stringify(userInfo), hashCode).toString();
};

// Function to decrypt user information
const decryptUserInfo = (encryptedUserInfo: string) => {
  try {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedUserInfo, hashCode).toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return initUser;
  }
};

export const useUserStore = create<UserState>() (
  persist<UserState>(
    (set, get) => ({
      userInfo: encryptUserInfo(initUser),
      updateUser: (userInfo: User) => {
        set(
          produce((draft: UserState) => {
            draft.userInfo = encryptUserInfo(userInfo);
          })
        )
      },
      getUser: () => {
        const state = get();
        if (typeof state.userInfo === 'object') {
          return state.userInfo;
        } else if (typeof state.userInfo === 'string') {
          return decryptUserInfo(state.userInfo);
        } else {
          return initUser;
        }
      }
    }), {
      name: "user",
    } as PersistOptions<UserState>
  )
);
